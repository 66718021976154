import React from 'react';
import styled from 'styled-components';
import H3 from '../_common/H3';
import Form, { StyledForm } from '../Form';
import { colors, fonts, media } from '../../globalStyles';
import Input from '../_common/Input';
import Textarea from '../_common/Textarea';
import arrow from '../../assets/img/sort-down.svg';
import Button from '../_common/Button';

const StyledFormWrapper = styled.div`
	width: 50%;
	min-height: 1051px;
	background: rgba(209, 209, 209, 0.59);
	padding-bottom: 134px;
	${H3} {
		line-height: 52px;
		font-family: ${fonts.openSansSembiBold};
		text-align: center;
		font-size: 36px;
		max-width: 410px;
		margin: 0 auto;
		color: #333;
		padding: 81px 0 55px;
		.pxl {
			color: ${colors.orange};
		}
	}
	${StyledForm} {
		form {
			max-width: 518px;
			margin: 0 auto;
		}
		${Input}, ${Textarea} {
			border-radius: 0;
			background-color: rgba(255, 255, 255, 0.67);
			border: 1px solid rgba(132, 132, 132, 0.5);
			&::placeholder {
				color: #999;
			}
		}
		.form-item {
			position: relative;
			.arrow-triangle {
				background-image: url(${arrow});
				width: 12px;
				height: 12px;
				position: absolute;
				right: 20px;
				top: 16px;
				background-size: contain;
			}
			.selected-option-overflow {
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
			}
		}
		${Button} {
			display: block;
			width: 100%;
			text-align: center;
		}
	}
	@media(max-width: 1100px) {
		${StyledForm} {
			form{
				max-width: 400px;
			}
		}
	}
	${media.maxMd`
		width: 100%;
		padding: 0 15px 80px;
		min-height: 0;
		${StyledForm}{
			form{
				max-width: 70%;
			}
		}
	`}
	${media.maxSm`
		${StyledForm}{
			form{
				max-width: 100%;
			}
		}
	`}
	${media.maxXs`
		${H3} {
			font-size: 28px;
			line-height: 1;
		}
	`}
`;
const FormComponent = () => (
	<StyledFormWrapper>
		<H3>
			At <span className="pxl">Pixelteh</span>, you always talk to a human!
		</H3>
		<Form label="Contact Form" contactPage sendBtnText="Send" />
	</StyledFormWrapper>
);

export default FormComponent;
