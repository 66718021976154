import React from 'react';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import styled from 'styled-components';
import { StyledSectionHeader } from '../ContentSection/SectionHeader';
import { media, colors, fonts } from '../../globalStyles';
import { Link } from 'gatsby';

const StyledDescriptionBlock = styled.div`
	${P} {
		margin: 0 auto;
		margin-top: 10px;
		margin-bottom: 0;
		text-align: left;
		a {
			text-decoration: none;
			color: ${colors.blackTitle};
			font-family: ${fonts.openSansBold};
		}
	}
	${StyledSectionHeader} {
		padding-bottom: 13px;
	}
	${media.maxSm`
		${P} {
			text-align: center;
		}
	`}
`;

const DescriptionBlock = () => (
	<StyledDescriptionBlock>
		<PageDescription
			title="Let’s make something great together."
			bgColor="white"
			paddingBottom={0}
		>
			<P className="text-left">
				<b>PixelTeh</b> ensures the best service possible with every project we
				undertake! We’d love to work with you – whether you need
				<Link to={'/'}> web development</Link>, , data integration, ERP support,
				or eCommerce development. Interested in learning more about our
				services? We are happy to discuss your needs and help you make smart
				decisions by offering target solutions for company.
			</P>
			<P className="text-left">
				Getting started is easy! Simply complete the form below, and one of our
				project managers will respond to you as soon as possible. We look
				forward to working with you and growing your business.
			</P>
		</PageDescription>
	</StyledDescriptionBlock>
);

export default DescriptionBlock;
