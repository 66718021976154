import React from 'react';
import styled from 'styled-components';

import map from '../../assets/img/map-pixel.jpg';
import FormComponent from './FormComponent';
import ContactsComponent from './ContactsComponent';
import { media } from '../../globalStyles';

const StyledContactSection = styled.section`
	background: url(${map}) repeat-x 0 center;
	display: flex;
	box-shadow: inset 0px -240px 99px -4px #ffffff, inset 0px 270px 99px -4px #fff;
	${media.maxMd`
		flex-direction: column;
		background: none;
	`}
`;

const ContactSection = () => (
	<StyledContactSection id="contact-section">
		<FormComponent />
		<ContactsComponent />
	</StyledContactSection>
);

export default ContactSection;
