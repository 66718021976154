import React from 'react';
import styled from 'styled-components';

import H1 from '../_common/H1';

import { colors, fonts, media } from '../../globalStyles';

import fb from '../../assets/img/facebook-icon.png';
import twitter from '../../assets/img/twitter-icon.png';
import linkedin from '../../assets/img/linkedin-icon.png';
import { SOCIALS } from '../../socialsConfig';

const StyledContactsComponent = styled.div`
	background: rgba(6, 41, 86, 0.8);
	text-align: center;
	padding-top: 150px;
	position: relative;
	min-height: 1127px;
	width: 50%;
	.triangle {
		width: 0;
		height: 0;
		border-left: 55px solid transparent;
		border-right: 55px solid transparent;
		border-top: 93px solid #e74c3c;
		margin: 0 auto;
		margin-bottom: 0px;
		margin-bottom: 119px;
	}
	${H1} {
		text-align: center;
		color: #d7e3f2;
		font-family: ${fonts.loveloLineLight};
		font-size: 100px;
		font-weight: 400;
		line-height: 100px;
		text-transform: uppercase;
		margin-bottom: 25px;
	}
	address {
		font-style: normal;
		color: #f3f3f3;
		font-family: ${fonts.loveloBlack};
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 75px;
	}
	.phone {
		color: white;
		font-family: ${fonts.loveloBlack};
		font-size: 48px;
		font-weight: 400;
		line-height: 44px;
		margin-bottom: 77px;
	}
	.socials {
		display: flex;
		max-width: 234px;
		justify-content: space-between;
		margin: 0 auto 81px;
	}
	.social-item {
		display: flex;
		justify-content: space-around;
		align-items: center;
    width: 58px;
    height: 58px;
    background: #30465e;
    border: 1px solid #546986;
	}
	.email-link {
		color: ${colors.orange};
    font-family: ${fonts.robotoMedium};
    font-size: 48px;
    font-weight: 500;
    line-height: 22px;
    text-decoration: underline;
	}
	${media.maxMd`
		width: 100%;
		padding: 80px 15px;
		min-height: 0;
		.triangle{
			margin-bottom: 80px;
		}
	`}
	${media.maxSm`
		.triangle{
			margin-bottom: 40px;
		}
		${H1} {
			font-size: 75px;
		}
		address{
			margin-bottom: 35px;
			font-size: 16px;
		}
		.phone {
			margin-bottom: 35px;
			font-size: 36px;
		}
		.email-link{
			font-size: 36px;
		}
		.socials{
			margin-bottom: 40px;
		}
	`}
	${media.maxXs`
		.triangle{
			border-left: 40px solid transparent;
			border-right: 40px solid transparent;
			border-top: 78px solid #e74c3c;
		}
		${H1}{
			font-size: 64px;
		}
		.email-link{
			font-size: 26px;
		}
	`}
`;
const socialLinks = [
	{
		href: SOCIALS.twitterUrl,
		icon: twitter,
		type: 'twitter',
	},
	{
		href: SOCIALS.facebookUrl,
		icon: fb,
		type: 'fb',
	},
	{
		href: SOCIALS.linkedinUrl,
		icon: linkedin,
		type: 'linkedin',
	},
];

const ContactsComponent = () => (
	<StyledContactsComponent>
		<div className="triangle" />
		<H1>Pixelteh</H1>
		<address>Ukraine / Zaporizhzhya / Mayakovs&apos;koho Avenue, 11</address>
		<div className="phone">38 050 060 12 58</div>
		<div className="socials">
			{socialLinks.map(item => (
				<a
					key={item.type}
					href={item.href}
					target="_blank"
					rel="noopener noreferrer"
					className="social-item"
				>
					<img src={item.icon} alt={item.type} />
				</a>
			))}
		</div>
		<a href="mailto:office@pixelteh.com" className="email-link">
			office@pixelteh.com
		</a>
	</StyledContactsComponent>
);

export default ContactsComponent;
