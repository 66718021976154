import React from 'react';
import PropTypes from 'prop-types';

import SubpageTopCover from '../components/SubpageTopCover';
import PageLayout from '../layouts/PageLayout';

import DescriptionBlock from '../components/ContactPage/PageDescription';
import ContactSection from '../components/ContactPage/ContactSection';
import ProgrammingHub from '../components/ProgrammingHub';
import { useContactPicture } from '../hooks/use-contact-picture';
import { contact } from '../seoConfig';

const Contact = ({ location }) => {
	const img = useContactPicture();
	return (
		<PageLayout location={location} seoConfig={contact}>
			<SubpageTopCover backgroundImg={img}>
				Contact Us Today!
				<br />
				Transform the Way You Do Business
			</SubpageTopCover>
			<DescriptionBlock />
			<ContactSection />
			<ProgrammingHub />
		</PageLayout>
	);
};
Contact.propTypes = {
	location: PropTypes.object,
};
export default Contact;
